const customerStateFilter = {
  false: {
    label: "Active",
    value: false,
  },
  true: {
    label: "Deleted",
    value: true,
  },
};

export default customerStateFilter;
