export const shippingOptions = [
  {
    label: "Target shipping date",
    key: "TARGET_SHIPPING_DATE",
  },
  {
    label: "Update date",
    key: "SHIPPING_DATE_LAST_UPDATE",
  },
  {
    label: "Shipping date",
    key: "SHIPPING_DATE",
  },
  {
    label: "Late days",
    key: "SHIPPING_DIFF_DAYS",
  },
];
