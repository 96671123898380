import { StatusUpdateMethod } from "@/enums/StatusUpdateMethod";

const statusUpdatedMethodFilter = {
  [StatusUpdateMethod.manual]: {
    label: "Manual",
    value: true,
  },
  [StatusUpdateMethod.automatic]: {
    label: "Automatic",
    value: false,
  },
};

export default statusUpdatedMethodFilter;
