import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";

const quoteAdminStatuses = [
  {
    key: QuoteAdminStatuses.Draft,
    value: "Draft",
  },
  {
    key: QuoteAdminStatuses.Received,
    value: "Received",
  },
  {
    key: QuoteAdminStatuses.Costing,
    value: "Costing",
  },
  {
    key: QuoteAdminStatuses.Authorization,
    value: "Authorization",
  },
  {
    key: QuoteAdminStatuses.Confirmed,
    value: "Confirmed",
  },
  {
    key: QuoteAdminStatuses.Quoted,
    value: "Quoted",
  },
  {
    key: QuoteAdminStatuses.Checkout,
    value: "Checkout",
  },
  {
    key: QuoteAdminStatuses.PartiallyOrder,
    value: "Partially order",
  },
  {
    key: QuoteAdminStatuses.Ordered,
    value: "Ordered",
  },
];

export default quoteAdminStatuses;
