import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7525df23"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "user-initial"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-avatar", {default: !$setup.avatar}]),
    style: _normalizeStyle($setup.userAvatarStyle)
  }, [
    _createVNode($setup["Loader"], {
      loading: $setup.loading,
      size: $props.size
    }, null, 8, ["loading", "size"]),
    ($setup.avatar)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $setup.avatar,
          alt: "avatar"
        }, null, 8, _hoisted_1))
      : ($props.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.name[0].toUpperCase()), 1))
        : (_openBlock(), _createBlock($setup["NIcon"], {
            key: 2,
            size: $props.isHeader ? 16 : $props.size / 2
          }, {
            default: _withCtx(() => [
              _createVNode($setup["DefaultAvatar"], { color: "currentColor" })
            ]),
            _: 1
          }, 8, ["size"]))
  ], 6))
}