import { QuoteStateFilterKey } from "@/enums/QuoteStateFilterKey";

const quoteStateFilter = {
  [QuoteStateFilterKey.active]: {
    label: QuoteStateFilterKey.active,
    value: false,
  },
  [QuoteStateFilterKey.deleted]: {
    label: QuoteStateFilterKey.deleted,
    value: true,
  },
};

export default quoteStateFilter;
