import { computed, h, ref } from "vue";
import OrderInterface from "@/types/OrderInterface";
import { NIcon, NIconWrapper } from "naive-ui";
import { useStore } from "vuex";
import { ProductionStageStatuses } from "@/enums/ProductionStageStatuses";
import ProductionStatusDone from "@/assets/icons/production-status-done.svg?inline";
import ProductionStatusError from "@/assets/icons/production-status-error.svg?inline";
import ProductionStatusInProcess from "@/assets/icons/production-status-in-process.svg?inline";
import ProductionStatusPause from "@/assets/icons/production-status-pause.svg?inline";
import ProductionStatusQueue from "@/assets/icons/production-status-queue.svg?inline";
import dataTableMethods from "@/composables/dataTableMethods";
import timeElapsedFormatMethods from "@/composables/timeElapsedFormatMethods";

export default () => {
  const updateProductionStageOrderId = ref("");
  const updateProductionStagePartId = ref("");
  const updateProductionStageStageId = ref("");
  const updateProductionStageStatus = ref("");
  const showUpdateProductionStageModal = ref(false);

  const { getProductionStatusClass } = dataTableMethods();

  const { getElapsedFromDate } = timeElapsedFormatMethods();

  const customerProductionStageRow = (row: OrderInterface) => {
    return !row.mfrOrderLineId
      ? undefined
      : h("div", { class: "production-stage" }, [
          row.mfrOrderLineId.prodStageUserUpdate
            ? h("div", { class: "last-updated-hint" }, [
                h(
                  "div",
                  { class: "last-updated-hint-name" },
                  `${row.mfrOrderLineId.prodStageUserUpdate.firstName} ${row.mfrOrderLineId.prodStageUserUpdate.lastName}`
                ),
                h(
                  "div",
                  { class: "last-updated-hint-elapsed" },
                  getElapsedFromDate(row.mfrOrderLineId.lastProdStageUpdate)
                ),
              ])
            : undefined,
          row.mfrOrderLineId.productionStageStatus
            ? h(
                NIconWrapper,
                {
                  size: 24,
                  borderRadius: 12,
                  class: [
                    "production-status",
                    getProductionStatusClass(
                      `${row.mfrOrderLineId.productionStageStatus}`
                    ),
                  ],
                },
                () => [
                  h(
                    NIcon,
                    {
                      size: 22,
                    },
                    {
                      default: () =>
                        h(
                          getProductionStatusIconName(
                            `${row.mfrOrderLineId?.productionStageStatus}`
                          )
                        ),
                    }
                  ),
                ]
              )
            : undefined,
          row.mfrOrderLineId.productionStage
            ? h(
                "div",
                `P${row.mfrOrderLineId.productionStage.processNumber}/${row.mfrOrderLineId.productionStage.processCount}:\n${row.mfrOrderLineId.productionStage.processName}`
              )
            : undefined,
          showCustomerOrderProductionStageEditButton(row)
            ? h("button", {
                class: "edit-cell-button",
                onClick: (event: Event) => {
                  event.stopPropagation();
                  onUpdateProductionStageClick(
                    row.mfrOrderLineId!.id,
                    row.partId!.id,
                    row.mfrOrderLineId!.productionStage.id,
                    row.mfrOrderLineId!.productionStageStatus
                  );
                },
              })
            : undefined,
        ]);
  };

  const showCustomerOrderProductionStageEditButton = (row: OrderInterface) => {
    return (
      row.mfrOrderLineId &&
      row.mfrOrderLineId.productionStage &&
      row.balance > 0 &&
      isSuperAdminRole
    );
  };

  const mfrProductionStageRow = (row: OrderInterface) => {
    return h("div", { class: "production-stage" }, [
      row.prodStageUserUpdate
        ? h("div", { class: "last-updated-hint" }, [
            h(
              "div",
              { class: "last-updated-hint-name" },
              `${row.prodStageUserUpdate.firstName} ${row.prodStageUserUpdate.lastName}`
            ),
            h(
              "div",
              { class: "last-updated-hint-elapsed" },
              getElapsedFromDate(row.lastProdStageUpdate)
            ),
          ])
        : undefined,
      row.productionStageStatus
        ? h(
            NIconWrapper,
            {
              size: 24,
              borderRadius: 12,
              class: [
                "production-status",
                getProductionStatusClass(`${row.productionStageStatus}`),
              ],
            },
            () => [
              h(
                NIcon,
                {
                  size: 22,
                },
                {
                  default: () =>
                    h(
                      getProductionStatusIconName(
                        `${row.productionStageStatus}`
                      )
                    ),
                }
              ),
            ]
          )
        : undefined,
      row.productionStage
        ? h(
            "div",
            `P${row.productionStage.processNumber}/${row.productionStage.processCount} - ${row.productionStage.processName}`
          )
        : undefined,
      showPurchaseOrderProductionStageEditButton(row)
        ? h("button", {
            class: "edit-cell-button",
            onClick: (event: Event) => {
              event.stopPropagation();
              onUpdateProductionStageClick(
                row.id,
                row.partId!.id,
                row.productionStage.id,
                row.productionStageStatus
              );
            },
          })
        : undefined,
    ]);
  };

  const onUpdateProductionStageClick = (
    orderId: string,
    partId: string,
    stageId: string,
    status: string
  ) => {
    updateProductionStageOrderId.value = orderId;
    updateProductionStagePartId.value = partId;
    updateProductionStageStageId.value = stageId;
    updateProductionStageStatus.value = status;
    showUpdateProductionStageModal.value = true;
  };

  const showPurchaseOrderProductionStageEditButton = (row: OrderInterface) => {
    return row.productionStage && row.balance > 0 && roleWithEditing.value;
  };

  const roleWithEditing = computed(() => {
    return isSuperAdminRole || isSupplyChainUserRole || isManufacturerUserType;
  });

  const store = useStore();
  const isSuperAdminRole = store.getters["user/isSuperAdminUserRole"];
  const isSupplyChainUserRole = store.getters["user/isSupplyChainUserRole"];
  const isManufacturerUserType = store.getters["user/isManufacturerUserType"];

  const getProductionStatusIconName = (productionStatus: string) => {
    switch (productionStatus) {
      case ProductionStageStatuses.IN_PROCESS:
        return ProductionStatusInProcess;
      case ProductionStageStatuses.DONE:
        return ProductionStatusDone;
      case ProductionStageStatuses.QUEUE:
        return ProductionStatusQueue;
      case ProductionStageStatuses.ERROR:
        return ProductionStatusError;
      case ProductionStageStatuses.PAUSE:
      default:
        return ProductionStatusPause;
    }
  };

  const onUpdateProductionStageModalClose = () => {
    showUpdateProductionStageModal.value = false;
    updateProductionStageOrderId.value = "";
    updateProductionStagePartId.value = "";
    updateProductionStageStageId.value = "";
    updateProductionStageStatus.value = "";
  };

  return {
    updateProductionStageOrderId,
    updateProductionStagePartId,
    updateProductionStageStageId,
    updateProductionStageStatus,
    showUpdateProductionStageModal,
    onUpdateProductionStageModalClose,
    customerProductionStageRow,
    mfrProductionStageRow,
  };
};
