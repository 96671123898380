import { computed, ref } from "vue";

export default () => {
  const limit = ref(0);
  const currentPage = ref(1);
  const totalCount = ref(0);

  const offset = computed(() => (currentPage.value - 1) * limit.value);
  const totalPages = computed(() => Math.ceil(totalCount.value / limit.value));

  const resetPagination = () => {
    currentPage.value = 1;
  };

  const paginationCurrentItemsMessage = computed(() => {
    const offsetPlusLimit = offset.value + limit.value;
    return `Showing ${offset.value + 1}-${
      offsetPlusLimit < totalCount.value ? offsetPlusLimit : totalCount.value
    } of ${totalCount.value}`;
  });

  return {
    limit,
    currentPage,
    totalCount,
    offset,
    totalPages,
    resetPagination,
    paginationCurrentItemsMessage,
  };
};
