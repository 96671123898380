export default () => {
  const getElapsedFromDate = (
    dateString: string | Date,
    withUpdatedText = false
  ): string => {
    const date = new Date(dateString);
    const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    return `${withUpdatedText ? "Updated " : ""}${getElapsedFromSeconds(
      seconds,
      "ago"
    )}`;
  };

  const getElapsedFromTime = (time: number) => {
    const seconds = Math.round(time / 1000);
    return getElapsedFromSeconds(seconds);
  };

  const getElapsedFromSeconds = (seconds: number, additionalText?: string) => {
    let interval = seconds / 31536000;
    if (interval < 0) {
      return "";
    }
    if (interval >= 1) {
      return getElapsedString(interval, "year", additionalText);
    }
    interval = seconds / 2592000;
    if (interval >= 1) {
      return getElapsedString(interval, "month", additionalText);
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return getElapsedString(interval, "day", additionalText);
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return getElapsedString(interval, "hour", additionalText);
    }
    interval = seconds / 60;
    if (interval > 1) {
      return getElapsedString(interval, "minute", additionalText);
    }
    return getElapsedString(seconds, "second", additionalText);
  };

  const getElapsedString = (
    interval: number,
    unit: string,
    additionalText?: string
  ) => {
    const result = Math.floor(interval);
    if (additionalText) {
      if (result === 0 && unit === "second") {
        return "just now";
      }
      return `${result} ${unit}${result === 1 ? "" : "s"} ${additionalText}`;
    }
    return `${result} ${unit}${result === 1 || result === 0 ? "" : "s"}`;
  };

  return {
    getElapsedFromDate,
    getElapsedFromTime,
  };
};
