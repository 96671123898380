const complexityLevels = [
  {
    key: 3,
    value: "Low",
  },
  {
    key: 2,
    value: "Middle",
  },
  {
    key: 1,
    value: "High",
  },
  {
    key: 0,
    value: "Very high",
  },
];

export default complexityLevels;
