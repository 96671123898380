import { OpenOrderFilterKey } from "@/enums/OpenOrderFilterKey";

const openOrderFilter = {
  [OpenOrderFilterKey.open]: {
    label: "Open order lines",
    value: false,
  },
  [OpenOrderFilterKey.delivered]: {
    label: "Delivered order lines",
    value: true,
  },
};

export default openOrderFilter;
