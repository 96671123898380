export enum OrderPlatformStatuses {
  ContractReview = "Contract review",
  POAuthorization = "PO authorization",
  InProduction = "In production",
  RqcRfs = "RQC / RFS",
  Shipped = "Shipped",
  PODelivered = "PO Delivered",
  POCanceled = "PO Canceled",
  PRBid = "PR / Bid",
}
