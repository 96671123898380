import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";
import useAxiosInstance from "@/composables/useAxiosInstance";

localforage.defineDriver(memoryDriver);

const forageStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  name: "my-cache",
});

const axiosCacheInstance = setup({
  baseURL: process.env.VUE_APP_API_URL,
  cache: {
    maxAge: 15 * 60 * 1000,
    store: forageStore,
  },
});

useAxiosInstance(axiosCacheInstance);

export default axiosCacheInstance;
