import { DateFilterKeyEnum } from "@/enums/DateFilterKeyEnum";
import { getDateStringForFilter } from "@/composables/date/quickActionsComposable";

export const parseParams = (params: string): Record<string, string[]> => {
  const output: Record<string, string[]> = {};
  const searchParams = new URLSearchParams(params);
  new Set([...searchParams.keys()]).forEach((key) => {
    output[key.replace("[]", "")] = searchParams.getAll(key);
  });
  return output;
};

export const getMapKeyByValue = (
  map: Map<string, unknown>,
  value: string
): string | undefined => {
  return [...map].find(([_, val]) => val === value)?.[0] || undefined;
};

export const setDateFilters = (
  dates: DateFilterKeyEnum[],
  quickActionMap: Map<string, string>,
  dateRangeSet: Set<string>
): void => {
  dates.forEach((item) => {
    if (Object.values(DateFilterKeyEnum).includes(item)) {
      quickActionMap.set(item, getDateStringForFilter(item));
    } else {
      dateRangeSet.add(item);
    }
  });
};
