import { onMounted } from "vue";

export default () => {
  onMounted(() => {
    onMountedBlur();
  });

  const onMountedBlur = () => {
    const focusedElement = document.activeElement as HTMLElement;
    if (focusedElement) {
      focusedElement.blur();
    }
  };

  return {
    onMountedBlur,
  };
};
