import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NDropdown"], {
    class: "export-data main-dropdown",
    show: $setup.showMenu,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (($setup.showMenu) = $event)),
    options: $props.options,
    trigger: $props.trigger,
    onSelect: $setup.handleMenuOptionSelect,
    disabled: $setup.disabled,
    placement: $props.placement
  }, {
    default: _withCtx(() => [
      ($props.loading)
        ? (_openBlock(), _createBlock($setup["NSpin"], {
            key: 0,
            size: "small"
          }))
        : (_openBlock(), _createBlock($setup["NButton"], _mergeProps({
            key: 1,
            class: ["round-button dropdown-button", {disabled: $setup.disabled, horizontal: $props.horizontal, borderless: $props.borderless,     transparent: $props.transparent, 'circle-button': !$props.transparent, ghost: $props.ghost}],
            circle: "",
            disable: $props.loading,
            style: $setup.buttonStyle
          }, _ctx.$attrs, {
            onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => ($setup.showMenu = false), ["esc"]))
          }), {
            icon: _withCtx(() => [
              _createVNode($setup["NIcon"], {
                color: $setup.colors.iconColor
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, () => [
                    _createVNode($setup["ThreeDotMenu"], { color: "currentColor" })
                  ], true)
                ]),
                _: 3
              }, 8, ["color"])
            ]),
            _: 3
          }, 16, ["disable", "class", "style"]))
    ]),
    _: 3
  }, 8, ["show", "options", "trigger", "disabled", "placement"]))
}