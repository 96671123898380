import { ref } from "vue";
import { useLoadingBar } from "naive-ui";

export default () => {
  const loading = ref(false);
  const loadingBar = useLoadingBar();

  const startLoader = () => {
    loading.value = true;
    loadingBar.start();
  };

  const finishLoader = () => {
    loading.value = false;
    loadingBar.finish();
  };

  return {
    loading,
    loadingBar,
    startLoader,
    finishLoader,
  };
};
