import { OrderStatuses } from "@/enums/OrderStatuses";

export const OrderStatusesLabel = {
  [OrderStatuses.Confirmation]: "Confirmation",
  [OrderStatuses.Scheduling]: "Scheduling",
  [OrderStatuses.InProduction]: "In production",
  [OrderStatuses.QualityControl]: "Quality control",
  [OrderStatuses.ReadyForShipment]: "R. for shipment",
  [OrderStatuses.Shipped]: "Shipped",
  [OrderStatuses.Delivered]: "Delivered",
};
