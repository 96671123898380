import { DateFilterKeyEnum } from "@/enums/DateFilterKeyEnum";
import dayjs, { ManipulateType } from "dayjs";

export const getDateStringForFilter = (
  filterKey: DateFilterKeyEnum,
  format = "DD/MM/YYYY"
): string => {
  if (filterKey === DateFilterKeyEnum.overdue) {
    return `-${dayjs().format(format)}`;
  }
  let type: ManipulateType | null = null;
  switch (filterKey) {
    case DateFilterKeyEnum.thisWeek:
    case DateFilterKeyEnum.nextWeek:
      type = "week";
      break;
    case DateFilterKeyEnum.thisMonth:
    case DateFilterKeyEnum.nextMonth:
      type = "month";
      break;
    case DateFilterKeyEnum.thisQuarter:
      type = "quarter" as ManipulateType;
      break;
    case DateFilterKeyEnum.thisYear:
      type = "year";
      break;
    default:
      break;
  }
  if (!type) {
    return "";
  }
  const startOfRange = filterKey.includes("Next")
    ? dayjs().add(1, type)
    : dayjs();
  return `${startOfRange.startOf(type).format(format)}-${startOfRange
    .endOf(type)
    .format(format)}`;
};
