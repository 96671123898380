export default () => {
  const getFlaggedLevelClass = (flaggedLevel: number): string => {
    if (flaggedLevel === 1) {
      return "low";
    }
    if (flaggedLevel === 2) {
      return "middle";
    }
    if (flaggedLevel === 3) {
      return "high";
    }
    return "";
  };
  return {
    getFlaggedLevelClass,
  };
};
