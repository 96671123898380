export const deliveryOptions = [
  {
    label: "Due date",
    key: "DUE_DATE",
  },
  {
    label: "Delivery date",
    key: "DELIVERY_DATE",
  },
  {
    label: "Days late / early",
    key: "DELIVERY_DIFF_DAYS",
  },
  {
    label: "Update date",
    key: "DELIVERY_DATE_LAST_UPDATE",
  },
];
