import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48516670"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "quick-filter-item--title" }
const _hoisted_3 = {
  key: 0,
  class: "quick-filter-item--count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["quick-filter-item", {'quick-filter-item-danger': $props.danger, 'quick-filter-item-active': $props.active}]),
    disabled: !$props.count && $props.haveFilterCounters,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.title), 1),
    ($props.haveFilterCounters)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.count || 0), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}