export const placeholderAvatarList: { [key: string]: any } = {
  slavik: require("../assets/placeholders/user-avatars/slavik.png"),
  marinovski: require("../assets/placeholders/user-avatars/slavik.png"),
  "slavik marinovski": require("../assets/placeholders/user-avatars/slavik.png"),
  igor: require("../assets/placeholders/user-avatars/igor.png"),
  braslavsky: require("../assets/placeholders/user-avatars/igor.png"),
  "igor braslavsky": require("../assets/placeholders/user-avatars/igor.png"),
  ido: require("../assets/placeholders/user-avatars/ido.png"),
  turgeman: require("../assets/placeholders/user-avatars/ido.png"),
  "ido turgeman": require("../assets/placeholders/user-avatars/ido.png"),
};
