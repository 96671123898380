export enum QuoteAdminStatuses {
  Draft = 0,
  NONE = 1,
  Received = 2,
  Costing = 3,
  Authorization = 4,
  Confirmed = 5,
  Quoted = 6,
  Checkout = 7,
  PartiallyOrder = 8,
  Ordered = 9,
}
