import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0eb7fcac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filter-tag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($props.label)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString($props.label) + ": ", 1)
            ], 64))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString($props.value), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["close-button", {disabled: $props.loading}]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('deleteItem')), ["prevent"]))
        }, null, 2)
      ]))
    : _createCommentVNode("", true)
}