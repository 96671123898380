import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ab203c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NTooltip"], {
    class: "tooltip--wrapper tooltip--wrapper-little",
    trigger: "hover",
    placement: "top-start",
    "show-arrow": false,
    disabled: $setup.disabledTooltip
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("p", {
        class: "text-with-tooltip",
        ref: "textRef"
      }, _toDisplayString($props.text), 513)
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString($props.text), 1)
    ]),
    _: 1
  }, 8, ["disabled"]))
}