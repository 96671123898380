export enum FileTypes {
  CSV = "text/csv",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ZIP = "application/zip",
  PDF = "application/pdf",
  PNG = "image/png",
  JPEG = "image/jpeg",
  HTML = "text/html",
}
