import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dates-wrapper" }
const _hoisted_2 = { class: "current-date" }
const _hoisted_3 = { class: "current-date-title" }
const _hoisted_4 = { class: "flex-wrapper" }
const _hoisted_5 = { class: "current-date-due-date" }
const _hoisted_6 = { class: "bold" }
const _hoisted_7 = { class: "date" }
const _hoisted_8 = { class: "current-date-delivery-date" }
const _hoisted_9 = { class: "bold" }
const _hoisted_10 = { class: "date" }
const _hoisted_11 = { class: "updated-date" }
const _hoisted_12 = { class: "updated-date-title" }
const _hoisted_13 = {
  key: 0,
  class: "days-difference"
}
const _hoisted_14 = {
  key: 0,
  class: "days-difference-current"
}
const _hoisted_15 = { class: "difference" }
const _hoisted_16 = {
  key: 0,
  class: "days-difference-arrow"
}
const _hoisted_17 = { class: "days-difference-updated" }
const _hoisted_18 = { class: "difference" }
const _hoisted_19 = { class: "update-delivery-date-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "update-delivery-date-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: $setup.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(`Current ${$setup.isCustomerView ? "shipping" : "delivery"} date`), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString($setup.isCustomerView ? "Target shipping date" : "Due Date"), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString($setup.formatDateToString($props.targetDate)), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(`${$setup.isCustomerView ? "Shipping" : "Delivery"} date`), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString($setup.dateForUpdate ? $setup.formatDateToString($setup.dateForUpdate) : "---"), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(`Updated ${$setup.isCustomerView ? "shipping" : "delivery"} date`), 1),
          _createVNode($setup["NDatePicker"], {
            class: "updated-date-datepicker",
            panel: "",
            value: $setup.updatedDate,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.updatedDate) = $event)),
            disabled: $setup.loading,
            placeholder: "Choose date",
            format: "MMM d, yyyy",
            "first-day-of-week": 0
          }, null, 8, ["value", "disabled"])
        ])
      ]),
      ($setup.dateForUpdate || $setup.updatedDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            ($setup.dateForUpdate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["days", {delay: $setup.daysDifference > 0}])
                  }, _toDisplayString(Math.abs($setup.daysDifference)), 3),
                  _createElementVNode("div", _hoisted_15, _toDisplayString($setup.daysDifferenceText($setup.daysDifference)), 1)
                ]))
              : _createCommentVNode("", true),
            ($setup.updatedDate)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  ($setup.dateForUpdate)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["days", {delay: $setup.updatedDaysDifference > 0}])
                    }, _toDisplayString(Math.abs($setup.updatedDaysDifference)), 3),
                    _createElementVNode("div", _hoisted_18, _toDisplayString($setup.daysDifferenceText($setup.updatedDaysDifference)), 1)
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_19, [
        _createVNode($setup["NButton"], {
          class: "submit-button n-button-save medium-button",
          type: "info",
          disabled: $setup.loading,
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onSave()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save")
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          disabled: $setup.loading,
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}