import { SortEnum } from "@/enums/Sort";
import { TableSort } from "@/enums/TableSort";
import { OrderByType } from "@/types/OrderByType";
import { ref } from "vue";

export default () => {
  const orderBy = ref<OrderByType>(null);
  const activeSortableId = ref<string | number>("");

  const getSortTriangleClass = (condition: boolean, orderType: SortEnum) => {
    return condition ? `sort-triangle ${TableSort[orderType]}` : "";
  };

  const changeSort = (sortableId: string) => {
    if (sortableId === "") {
      return;
    }
    if (sortableId === activeSortableId.value) {
      orderBy.value = orderBy.value
        ? orderBy.value === SortEnum.DESC
          ? SortEnum.ASC
          : null
        : SortEnum.DESC;
      if (!orderBy.value) {
        activeSortableId.value = "";
      }
    } else {
      activeSortableId.value = sortableId;
      orderBy.value = SortEnum.DESC;
    }
  };

  const getTriangleClass = (orderByKey: keyof typeof TableSort) => {
    return TableSort[orderByKey];
  };

  const sortBySortKey = (a: number, b: number, key: SortEnum) => {
    return key === SortEnum.ASC ? a - b : b - a;
  };
  const sortAlphabeticallyBySortKey = (
    a: string,
    b: string,
    key = SortEnum.ASC
  ) => {
    return key === SortEnum.ASC ? a.localeCompare(b) : b.localeCompare(a);
  };

  return {
    orderBy,
    activeSortableId,
    changeSort,
    getTriangleClass,
    sortBySortKey,
    sortAlphabeticallyBySortKey,
    getSortTriangleClass,
  };
};
