import { OrderStatuses } from "@/enums/OrderStatuses";
import {
  OrderStatusPercent,
  OrderStatusPercentForTimeline,
} from "@/enums/OrderStatusPercent";
import { ProductionStageInterface } from "@/types/ProductionStageInterface";

export const orderStatusToPercent = (
  status: OrderStatuses,
  productionStage: ProductionStageInterface | undefined
): number => {
  const percent = OrderStatusPercent[status];
  if (percent !== undefined) {
    return percent;
  }
  if (productionStage?.processNumber && productionStage?.processCount) {
    const percent =
      (productionStage.processNumber / productionStage.processCount) * 55 + 20;
    return Math.round(percent / 5) * 5;
  }
  return 20;
};

export const orderStatusToPercentForTimeline = (
  status: OrderStatuses,
  productionStage: ProductionStageInterface | undefined
): number => {
  if (status === OrderStatuses.InProduction) {
    if (productionStage?.processNumber && productionStage?.processCount) {
      const percent =
        (productionStage.processNumber / productionStage.processCount) * 34 +
        30;
      return Math.round(percent / 5) * 5;
    }
    return 30;
  }
  return OrderStatusPercentForTimeline[status];
};
