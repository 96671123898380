import { FileTypes } from "@/enums/FileTypes";

export default () => {
  const downloadFile = async (
    fileData: Blob,
    fileType: FileTypes,
    fileName: string
  ) => {
    const blob = new Blob([fileData], { type: fileType });
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    a.remove();
  };

  const downloadFileFromUrl = (url: string, fileName: string) => {
    return fetch(url).then((fetchRes) => {
      return fetchRes.blob().then((blob) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
        a.remove();
      });
    });
  };

  return {
    downloadFile,
    downloadFileFromUrl,
  };
};
