import { FileTypes } from "./FileTypes";

export const FileFormat = {
  CSV: {
    key: "CSV",
    name: "CSV",
    format: "csv",
    type: FileTypes.CSV,
  },
  XLSX: {
    key: "XLSX",
    name: "Excel",
    format: "xlsx",
    type: FileTypes.XLSX,
  },
};
