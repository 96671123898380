import { OrderStatuses } from "@/enums/OrderStatuses";
import { OrderStatusesLabel } from "@/enums/OrderStatusesLabel";

const OrderStatusList = [
  {
    label: OrderStatusesLabel[OrderStatuses.Confirmation],
    value: OrderStatuses.Confirmation,
  },
  {
    label: OrderStatusesLabel[OrderStatuses.Scheduling],
    value: OrderStatuses.Scheduling,
  },
  {
    label: OrderStatusesLabel[OrderStatuses.InProduction],
    value: OrderStatuses.InProduction,
  },
  {
    label: OrderStatusesLabel[OrderStatuses.QualityControl],
    value: OrderStatuses.QualityControl,
  },
  {
    label: OrderStatusesLabel[OrderStatuses.ReadyForShipment],
    value: OrderStatuses.ReadyForShipment,
  },
  {
    label: OrderStatusesLabel[OrderStatuses.Shipped],
    value: OrderStatuses.Shipped,
  },
  {
    label: OrderStatusesLabel[OrderStatuses.Delivered],
    value: OrderStatuses.Delivered,
  },
];

export default OrderStatusList;
