import { usePluralize } from "@/composables/usePluralize";

export const requiredMessage = (field: string): string => {
  return `${field} is required`;
};

export const notANumberValueMessage = (field: string): string => {
  return `${field} should be a number`;
};

export const minValueMessage = (field: string, value: number): string => {
  return `${field} is less than ${value}`;
};

export const minLengthMessage = (field: string, length: number): string => {
  return `${field} must be longer than ${length} ${usePluralize(
    length,
    "character"
  )}`;
};
export const maxLengthMessage = (field: string, length: number): string => {
  return `${field} should be up to ${length} ${usePluralize(
    length,
    "character"
  )} long`;
};
export const minMaxLengthMessage = (
  field: string,
  minLength: number,
  maxLength: number
): string => {
  return `${field} must be ${minLength}-${maxLength} characters long`;
};

export const alreadyInUseMessage = (field: string) => {
  return `This ${field} is already in use`;
};
export const alreadyAvailableMessage = (field: string) => {
  return `This ${field} already exists. Please enter a unique combination.`;
};
export const incorrectEmailMessage = "Please enter correct email";
export const incorrectLinkMessage = "Incorrect link";
export const invalidValueMessage = "Value is invalid";
export const notEmptyMessage = (field: string): string => {
  return `${field} should not be empty`;
};

export const notAllowedSpacesMessage = (field: string): string => {
  return `Spaces are not allowed in the ${field}`;
};
export const invalidEmailMessage =
  "Incorrect email address. You can use latin characters and special characters . _ -";
export const invalidPhoneMessage =
  "Phone number must start with + and have 7-15 digits";
export const invalidImageMessage = "Please, upload image file";
export const invalidFileType = (fileType: string): string => {
  return `Please choose a ${fileType} file`;
};
export const invalidMessage = (field: string): string => {
  return `Please enter a valid ${field}`;
};

export const imageFormatsErrorMessage =
  "The selected file is in the wrong format, please choose another file";

export const maxFractionalDigitsMessage = (
  field: string,
  fractionalDigitsLimit = 2
): string => {
  return `${field} is limited to ${fractionalDigitsLimit} digits for a fraction part`;
};

export const maxSafeIntegerValueMessage = (field: string): string => {
  return `${field} should be less than ${Number.MAX_SAFE_INTEGER}`;
};
