export const flaggedFilter = {
  1: {
    label: "Low",
    value: 1,
  },
  2: {
    label: "Medium",
    value: 2,
  },
  3: {
    label: "High",
    value: 3,
  },
};
