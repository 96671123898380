import { OrderStatuses } from "@/enums/OrderStatuses";

export const OrderStatusesShortLabel = {
  [OrderStatuses.Confirmation]: "Confirmation",
  [OrderStatuses.Scheduling]: "Scheduling",
  [OrderStatuses.InProduction]: "In production",
  [OrderStatuses.QualityControl]: "QC",
  [OrderStatuses.ReadyForShipment]: "Ready for Sh.",
  [OrderStatuses.Shipped]: "Shipped",
  [OrderStatuses.Delivered]: "Delivered",
};
