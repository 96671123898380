import { OrderStatuses } from "@/enums/OrderStatuses";

export const OrderStatusPercent: Record<OrderStatuses, number | undefined> = {
  [OrderStatuses.Confirmation]: 0,
  [OrderStatuses.Scheduling]: 10,
  [OrderStatuses.QualityControl]: 75,
  [OrderStatuses.ReadyForShipment]: 85,
  [OrderStatuses.Shipped]: 90,
  [OrderStatuses.Delivered]: 100,
  [OrderStatuses.InProduction]: undefined,
};

export const OrderStatusPercentForTimeline: Record<OrderStatuses, number> = {
  [OrderStatuses.Confirmation]: 0,
  [OrderStatuses.Scheduling]: 15,
  [OrderStatuses.InProduction]: 30,
  [OrderStatuses.QualityControl]: 64,
  [OrderStatuses.ReadyForShipment]: 76,
  [OrderStatuses.Shipped]: 88,
  [OrderStatuses.Delivered]: 100,
};
