import quoteAdminStatuses from "@/constants/quoteAdminStatuses";

export const getStatusName = (
  statusNumber: number,
  isDeleted = false
): string => {
  if (isDeleted) {
    return "Deleted";
  }
  return (
    quoteAdminStatuses.find((item) => item.key === statusNumber)?.value || ""
  );
};
