import { DeliveryStatuses } from "@/enums/DeliveryStatuses";

export const deliveryStatusesFilter = {
  [DeliveryStatuses.EARLY]: {
    label: "Early",
    value: DeliveryStatuses.EARLY,
  },
  [DeliveryStatuses.ON_TIME]: {
    label: "On time",
    value: DeliveryStatuses.ON_TIME,
  },
  [DeliveryStatuses.LATE]: {
    label: "Late",
    value: DeliveryStatuses.LATE,
  },
};

export default deliveryStatusesFilter;
