import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NDropdown"], {
    class: "export-data main-dropdown",
    show: $setup.showMenu,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showMenu) = $event)),
    options: $setup.options,
    trigger: "click",
    onSelect: $setup.exportData,
    placement: "bottom-end"
  }, {
    default: _withCtx(() => [
      ($setup.loading)
        ? (_openBlock(), _createBlock($setup["NSpin"], {
            key: 0,
            size: "small"
          }))
        : (_openBlock(), _createBlock($setup["NButton"], {
            key: 1,
            class: "circle-button round-button",
            circle: "",
            disabled: $props.disabled || $setup.loading
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ThreeDotMenu"], {
                size: 18,
                dotsColor: $setup.colors.iconColor
              }, null, 8, ["dotsColor"])
            ]),
            _: 1
          }, 8, ["disabled"]))
    ]),
    _: 1
  }, 8, ["show", "options"]))
}