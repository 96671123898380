import { AxiosResponse } from "axios";
import ApiService from "@/services";
import { FilterGroupedInterface } from "@/types/filter/FilterGroupedInterface";
import { FilterCategoriesEnum } from "@/enums/FilterCategoriesEnum";

export default class FiltersService {
  static getFilters(params: {
    category: FilterCategoriesEnum;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/filters", { params });
  }

  static addFilter(
    params: Pick<
      FilterGroupedInterface,
      "filterName" | "filterParams" | "category"
    >
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/filters", {
      editable: true,
      ...params,
    });
  }

  static deleteFilter(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/filters/${id}`);
  }
}
