import router from "@/router";

export const hasHistory = (): boolean => {
  return window.history.length > 2;
};

export const goToPreviousPage = (): void => {
  if (hasHistory()) {
    router.go(-1);
  } else {
    router.push("/");
  }
};
