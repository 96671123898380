import { h } from "vue";
import { orderStatusToPercent } from "@/composables/orders/orderStatusToPercent";
import timeElapsedFormatMethods from "@/composables/timeElapsedFormatMethods";
import { OrderStatusesLabel } from "@/enums/OrderStatusesLabel";
import CustomerOrderInterface from "@/types/CustomerOrderInterface";

export default () => {
  const { getElapsedFromDate } = timeElapsedFormatMethods();
  const getCustomerStatusCell = (row: CustomerOrderInterface) => {
    const percent = orderStatusToPercent(
      row.customerStatus,
      row.mfrOrderLineId?.productionStage
    );

    return h("div", { class: "order-status--wrapper with-last-update" }, [
      OrderStatusesLabel[row.customerStatus],
      h("div", { class: "order-status--progress" }, [
        row.customerStatusLastUpdate
          ? h(
              "div",
              { class: "last-updated-hint" },
              h(
                "div",
                { class: "last-updated-hint-elapsed" },
                getElapsedFromDate(row.customerStatusLastUpdate)
              )
            )
          : undefined,
        `${percent}%`,
        h(
          "div",
          {
            class: "order-status--progress-bar",
          },
          [
            h("div", {
              class:
                "order-status--progress-bar order-status--progress-bar-fill",
              style: `width: ${percent}%;`,
            }),
          ]
        ),
      ]),
    ]);
  };

  return {
    getCustomerStatusCell,
  };
};
