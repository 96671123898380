import { AxiosResponse } from "axios";

import ApiService from "@/services";
import { ExportParamsInterface } from "@/types/api/ExportParamsInterface";
import { ManualStatusParamsInterface } from "@/types/api/ManualStatusParamsInterface";
import { QCReportType } from "@/enums/QCReportType";

export default class OrdersService {
  static getOrderList(params: {
    limit: number;
    skip: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/orders/lines", { params });
  }

  static getCustomerOrderList(params: {
    limit: number;
    skip: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/orders/customer/lines", { params });
  }

  static getManufacturerOrderList(params: {
    limit: number;
    skip: number;
  }): Promise<AxiosResponse> {
    return ApiService.get("/v1/mfr-orders/lines", { params });
  }
  static getPartDocuments(params: {
    id: string;
    rfqId?: string;
  }): Promise<AxiosResponse> {
    return ApiService.get(`/v1/parts/documents/${params.id}`, { params });
  }

  static updateDeliveryDateForOrderLine(params: {
    id: string;
    deliveryDate: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/orders/lines/${params.id}`, {
      deliveryDate: params.deliveryDate,
    });
  }

  static updateShippingDateForOrderLine(params: {
    id: string;
    shippingDate: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/orders/lines/${params.id}`, {
      shippingDate: params.shippingDate,
    });
  }

  static updateDeliveryDateForManufacturerOrderLine(params: {
    id: string;
    deliveryDate: string;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/mfr-orders/lines/${params.id}`, {
      deliveryDate: params.deliveryDate,
    });
  }

  static updateBatchBalanceForManufacturerOrderLine(
    id: string,
    batchBalance: number
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/mfr-orders/lines/${id}`, { batchBalance });
  }

  static getOrderListFiltersOptionData(): Promise<AxiosResponse> {
    return ApiService.get("/v1/orders/for-filters");
  }

  static getManufacturerOrderListFiltersOptionData(): Promise<AxiosResponse> {
    return ApiService.get("/v1/mfr-orders/for-filters");
  }

  static getCustomerOrderListFiltersOptionData(): Promise<AxiosResponse> {
    return ApiService.get("/v1/orders/customer/for-filters");
  }

  static updateEscalationForOrderLine(params: {
    id: string;
    flaggedLevel: number;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/orders/lines/${params.id}`, {
      flaggedLevel: params.flaggedLevel,
    });
  }

  static updateEscalationForManufacturerOrderLine(params: {
    id: string;
    flaggedLevel: number;
  }): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/mfr-orders/lines/${params.id}`, {
      flaggedLevel: params.flaggedLevel,
    });
  }

  static exportOrderList(
    params: ExportParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/orders/export", {
      params,
      responseType: "arraybuffer",
    });
  }

  static exportManufacturerOrderList(
    params: ExportParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/mfr-orders/export", {
      params,
      responseType: "arraybuffer",
    });
  }

  static exportCustomerOrderList(
    params: ExportParamsInterface
  ): Promise<AxiosResponse> {
    return ApiService.get("/v1/orders/export", {
      params,
      responseType: "arraybuffer",
    });
  }

  static getOrderLineById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/orders/lines/${id}`);
  }

  static getCustomerOrderLineById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/orders/customer/lines/${id}`);
  }

  static getManufacturerOrderLineById(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/mfr-orders/lines/${id}`);
  }

  static getProductionStages(partId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/production-processes/parts/${partId}`);
  }

  static updateProductionStage(
    id: string,
    status: string,
    stageId: string
  ): Promise<AxiosResponse> {
    return ApiService.patch(`/v1/mfr-orders/lines/${id}`, {
      productionStageStatus: status,
      productionStage: stageId,
    });
  }

  static updateManualStatusForOrderLine(
    payload: ManualStatusParamsInterface
  ): Promise<AxiosResponse> {
    const { id, ...params } = payload;
    return ApiService.patch(`/v1/orders/lines/${id}`, params);
  }

  static uploadQcReport(params: {
    orderLineId: string;
    fileId: string;
    reportType: QCReportType;
  }): Promise<AxiosResponse> {
    return ApiService.post(`/v1/orders/lines/qc-reports`, null, { params });
  }

  static deleteQcReport(id: string): Promise<AxiosResponse> {
    return ApiService.delete(`/v1/orders/lines/qc-reports/${id}`);
  }
}
